import { useCallback, useEffect, useReducer, useState } from "react";
import { Block, PressedKeys, Player, Position, Distance, PlayerAction, BlockHistoryItem } from "../Types";
import { exportToJson, getNextMove, isPositionsEqaul, keysToPlayerActions } from "../Helpers";

type PlayersProp = {
    count: number;
    block?: Block;
    onBaseReached: () => void;
    onExportSuccess: () => void;

    timerInterval: number;
    pressedKeys: PressedKeys;

    blockSnapSize: number;
    areaWidth: number;
    areaHeight: number;
    isOnPause: boolean;
    isSlow: boolean;
    isGoToBase: boolean;
    isOnItself: boolean;
    temperature: number;
    targetLen: number;
}

type ChangeAction =
    | {
        type: "checkMovement";
        block: Block | undefined;
        blockSnapSize: number;
        pressedKeys: PressedKeys;
        isGoToBase: boolean;
        isSlow: boolean;
        isOnItself: boolean;
        areaWidth: number;
        areaHeight: number;
        onBaseReached: () => void;
    }
    | { type: "pressedKeys"; block: Block; blockSnapSize: number; }
    | { type: "clearHistory"; }
    | { type: "addMouseClick"; mouseClickPosition: Position; }
    | { type: "initPosition"; initialState: InitialState; }
    | { type: "deleteActionState"; id: string; }
    | { type: "setPlayerActions"; id: number; playerActions: PlayerAction[]; }
    | { type: "saveActionsState"; }
    | { type: "setRequestProgress"; id: number; isInProgress: boolean; }

type InitialState = {
    count: number;
    blockSnapSize: number;
    areaWidth: number;
    areaHeight: number;
}

const playersInitializer = (initState: InitialState): Player[] => {
    const players: Player[] = [];

    for (let id of Array.from({ length: initState.count }, (_, i) => i)) {
        const position: Position = [
            id * initState.blockSnapSize,
            (Math.round((initState.areaHeight) / initState.blockSnapSize) * initState.blockSnapSize) - initState.blockSnapSize
        ];
        players.push({
            id: id,
            startPosition: position,
            position: position,
            blockHistory: [],
            actionHistory: [],
            mouseClickPositions: [],
            actionsState: [],
            currentTimeSkipInterval: 0,
            actions: [],
            isRequestInProgress: false
        });
    }

    return players;
};

const clearHistory = (player: Player) => {
    player.blockHistory = [];
    player.actionHistory = [];
};

const clearMouseClicks = (player: Player) => {
    player.mouseClickPositions = [];
};

const playersReducer = (players: Player[], action: ChangeAction): Player[] => {
    switch (action.type) {
        case "checkMovement":
            for (const player of players) {
                let blockDistance: Distance | null = null;
                let currentBlockHistoryItem: BlockHistoryItem;
                if (action.block) {
                    blockDistance = getBlockDistance(player.position, action.block.position, action.blockSnapSize);
                    currentBlockHistoryItem = {
                        position: blockDistance,
                        eyeIndicator: action.block.eyeIndicator,
                        isEyeDown: action.block.isEyeDirectionDown,
                    };
                    if (!action.isSlow) {
                        player.blockHistory = [...player.blockHistory.slice(-5), currentBlockHistoryItem];
                    }

                    if (player.mouseClickPositions && player.mouseClickPositions.length) {
                        const [nextPosition, nextPositionKeys] = getNextMove(player.position, player.mouseClickPositions, action.blockSnapSize);
                        if (nextPosition && !action.isGoToBase && !isPositionsEqaul(nextPosition, player.position)) {
                            if (action.isSlow && blockDistance) {
                                player.actionHistory = [...player.actionHistory, { playerAction: keysToPlayerActions(nextPositionKeys), blockHistory: currentBlockHistoryItem }];
                            }
                            player.position = nextPosition;
                        }
                        const mouseClickPosition = player.mouseClickPositions[0];
                        if (mouseClickPosition) {
                            if (isPositionsEqaul(mouseClickPosition, player.position)) {
                                player.mouseClickPositions = player.mouseClickPositions.slice(1);
                            }
                        }
                    } else if (action.pressedKeys && action.pressedKeys.length > 0) {
                        const playerAction = keysToPlayerActions(action.pressedKeys);
                        if (playerAction[0] != 0) {
                            player.position[0] = playerAction[0] == -1
                                ? player.position[0] - action.blockSnapSize
                                : player.position[0] + action.blockSnapSize
                        }
                        if (playerAction[1] != 0) {
                            player.position[1] = playerAction[1] == -1
                                ? player.position[1] - action.blockSnapSize
                                : player.position[1] + action.blockSnapSize
                        }

                        if (action.isSlow && blockDistance) {
                            player.actionHistory = [...player.actionHistory, { playerAction: playerAction, blockHistory: currentBlockHistoryItem }];
                        }
                    } else {
                        if (action.isSlow && blockDistance) {
                            player.actionHistory = [...player.actionHistory, { playerAction: [0, 0], blockHistory: currentBlockHistoryItem }];
                        }
                    }
                }

                if (action.isGoToBase) {
                    let isInPosition = true;
                    const xStartPosition = player.startPosition[0];
                    if (player.position[0] != xStartPosition) {
                        isInPosition = false;
                        if (player.position[0] > xStartPosition) {
                            player.position[0] -= action.blockSnapSize;
                        }
                        if (player.position[0] < xStartPosition) {
                            player.position[0] += action.blockSnapSize;
                        }
                    }
                    const y_start_position = player.startPosition[1];
                    if (player.position[1] != y_start_position) {
                        isInPosition = false;
                        if (player.position[1] < y_start_position) {
                            player.position[1] += action.blockSnapSize;
                        }
                        if (player.position[1] > y_start_position) {
                            player.position[1] -= action.blockSnapSize;
                        }
                    }

                    if (isInPosition) {
                        action.onBaseReached();
                    }
                }

                if (action.isOnItself) {
                    if (player.actions && player.actions.length > 0) {
                        const player_action = player.actions.shift();
                        if (player_action) {
                            if (player_action[0] === 1) {
                                player.position[0] += action.blockSnapSize;
                            } else if (player_action[0] === -1) {
                                player.position[0] -= action.blockSnapSize;
                            }
                            if (player_action[1] === 1) {
                                player.position[1] += action.blockSnapSize;
                            } else if (player_action[1] === -1) {
                                player.position[1] -= action.blockSnapSize;
                            }
                        }
                    }
                }
            }
            return [...players];
        case "pressedKeys":
            for (const player of players) {
                // const blockDistance = getBlockDistance(player.position, action.block.position, action.blockSnapSize);
                // player.blockHistory = [...player.blockHistory.slice(-5), blockDistance];
            }
            return players;
        case "clearHistory":
            for (const player of players) {
                clearHistory(player);
            }
            return players;
        case "addMouseClick":
            for (const player of players) {
                player.mouseClickPositions.push(action.mouseClickPosition);
            }
            return players;
        case "initPosition":
            return playersInitializer(action.initialState);
        case "saveActionsState":
            for (const player of players) {
                if (player.actionHistory.length > 0) {
                    player.actionsState.push({
                        id: `${player.id}_${player.actionsState.length + 1}`,
                        items: player.actionHistory,
                        blockHistory: player.blockHistory,
                    });
                }
            }
            return players;
        case "deleteActionState":
            for (const player of players) {
                for (const actionState of player.actionsState) {
                    if (actionState.id === action.id) {
                        player.actionsState = player.actionsState.filter((a) => a.id !== action.id)
                        return players;
                    }
                }
            }
            return players;
        case "setPlayerActions":
            for (const player of players) {
                if (player.id === action.id) {
                    player.actions = action.playerActions;
                }
            }
            return players;
        case "setRequestProgress":
            for (const player of players) {
                if (player.id === action.id) {
                    player.isRequestInProgress = action.isInProgress;
                }
            }
            return players;
        default:
            return players;
    }
}

const normalizeCoordinate = (coordinate: number, blockSnapSize: number): number => {
    return Math.round((coordinate - blockSnapSize) / blockSnapSize);
}

const getBlockDistance = (player: [number, number], block: [number, number], blockSnapSize: number): [number, number] => {
    const x = normalizeCoordinate(player[0] - block[0], blockSnapSize) + 1;
    const y = normalizeCoordinate(player[1] - block[1], blockSnapSize) + 1;

    return [x, y]
}

const usePlayers = (props: PlayersProp): [
    Player[],
    () => void,
    React.Dispatch<React.SetStateAction<boolean>>,
    (position: Position) => void,
    (newBlockSnapSize: number) => void,
    () => void,
    () => void,
    (id: string) => void,
    () => void,
] => {
    const [players, dispatchChange] = useReducer(
        playersReducer,
        {
            count: props.count,
            blockSnapSize: props.blockSnapSize,
            areaWidth: props.areaWidth,
            areaHeight: props.areaHeight,
        },
        playersInitializer
    );

    const [isToClearHistory, setClearHistory] = useState<boolean>(false);

    useEffect(() => {
        if (!isToClearHistory) {
            return;
        }

        dispatchChange({ type: "clearHistory" });
        setClearHistory(false);
    }, [isToClearHistory]);

    const addMouseClickPosition = useCallback((position: Position) => {
        dispatchChange({ type: "addMouseClick", mouseClickPosition: position });
    }, []);

    const initPosition = useCallback((newBlockSnapSize: number) => {
        dispatchChange({
            type: "initPosition", initialState: {
                count: props.count,
                blockSnapSize: newBlockSnapSize,
                areaWidth: props.areaWidth,
                areaHeight: props.areaHeight,
            }
        });
    }, []);

    const printPlayers = useCallback(() => {
        for (const player of players) {
            console.log(player);
        }
    }, [players]);

    const exportPlayers = useCallback(() => {
        for (const player of players) {
            // exportToJson(player.actionsState);
            // console.log("Exported data", player.actionsState);

            const url = new URL(`${process.env.REACT_APP_PYTHON_BACKEND}/export`);

            const token = localStorage.getItem("jwt");
            if (!token) {
                throw new Error("Not authorized");
            }

            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(player.actionsState)
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    return Promise.reject(response);
                })
                .then((_) => {
                    props.onExportSuccess();
                    setClearHistory(true);
                })
                .catch((reason: any) => {
                    if ("status" in reason) {
                        if (reason.status === 401) {
                            window.location.href = "/";
                        }
                    } else {
                        alert("An error occured during exporting. Please, try later.");
                    }
                });
        }
    }, [players]);

    const checkMovement = () => {
        dispatchChange({
            type: "checkMovement",
            block: props.block,
            blockSnapSize: props.blockSnapSize,
            pressedKeys: props.pressedKeys,
            isGoToBase: props.isGoToBase,
            isSlow: props.isSlow,
            isOnItself: props.isOnItself,
            areaWidth: props.areaWidth,
            areaHeight: props.areaHeight,
            onBaseReached: props.onBaseReached,
        });
    };

    const deleteActionState = useCallback((id: string) => {
        dispatchChange({
            type: "deleteActionState",
            id: id,
        });
    }, []);

    const saveActionState = useCallback(() => {
        dispatchChange({
            type: "saveActionsState",
        });
    }, []);

    useEffect(() => {
        if (!props.isOnItself) {
            return;
        }
        for (const player of players) {
            if (player.actions && player.actions.length > 0) {
                continue;
            }

            const url = new URL(`${process.env.REACT_APP_PYTHON_BACKEND}/pred_player_actions`);

            const token = localStorage.getItem("jwt");
            if (!token) {
                throw new Error("Not authorized");
            }

            if (player.isRequestInProgress) {
                continue;
            }

            dispatchChange({
                type: "setRequestProgress",
                id: player.id,
                isInProgress: true,
            })

            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify({
                    history: player.blockHistory,
                    temperature: props.temperature,
                    targetLen: props.targetLen,
                })
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    return Promise.reject(response);
                })
                .then((data) => {
                    let newActions = data.map((d: any) => [d[0], d[1]]);
                    dispatchChange({
                        type: "setPlayerActions",
                        id: player.id,
                        playerActions: newActions,
                    })
                })
                .catch((reason: any) => {
                    if ("status" in reason) {
                        if (reason.status === 401) {
                            window.location.href = "/";
                        }
                    }
                })
                .finally(() => {
                    dispatchChange({
                        type: "setRequestProgress",
                        id: player.id,
                        isInProgress: false,
                    })
                });
        }
    }, [props.isOnItself, players.map((p) => p.actions)])

    return [
        players,
        checkMovement,
        setClearHistory,
        addMouseClickPosition,
        initPosition,
        printPlayers,
        exportPlayers,
        deleteActionState,
        saveActionState,
    ];
}


export default usePlayers;
export type {
    PlayersProp,
    ChangeAction
};
