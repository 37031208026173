import { ActionState, PlayerAction, Position, PressedKeys, NextPosition } from "./Types";

const getRandom = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
}

const normalizeCoordinate = (
    coordinate: number,
    blockSnapSize: number
): number => {
    return Math.round((coordinate - blockSnapSize) / blockSnapSize);
}

const getBlockDistance = (
    player: [number, number],
    block: [number, number],
    blockSnapSize: number
): [number, number] => {
    const x = normalizeCoordinate(player[0] - block[0], blockSnapSize) + 1;
    const y = normalizeCoordinate(player[1] - block[1], blockSnapSize) + 1;

    return [x, y]
}


const keysToPlayerActions = (keys: PressedKeys): PlayerAction => {
    const actionsKeys: PlayerAction = [0, 0];

    if (!keys) {
        return actionsKeys;
    }

    if (keys[0]) {
        actionsKeys[0] = keys[0] == "ArrowRight" ? 1 : -1;
    }
    if (keys[1]) {
        actionsKeys[1] = keys[1] == "ArrowUp" ? -1 : 1;
    }

    return actionsKeys;
}


const isPositionsEqaul = (
    position1: Position | null | undefined,
    position2: Position | null | undefined
) => {
    if (!position1 && !position2) {
        return false;
    }
    if (!position1) {
        return false;
    }
    if (!position2) {
        return false;
    }

    return position1[0] === position2[0] && position1[1] === position2[1];
}


const getNextMove = (
    playerPosition: Position,
    nextPositions: Position[] | undefined,
    shift: number
): NextPosition => {
    if (!nextPositions || nextPositions.length == 0) {
        return [undefined, undefined];
    }

    const np = nextPositions[0];

    const newPosition: Position = [playerPosition[0], playerPosition[1]];
    const pressedKeys: PressedKeys = [null, null];

    if (playerPosition[0] > np[0]) {
        newPosition[0] = newPosition[0] - shift;
        pressedKeys[0] = "ArrowLeft";
    } else if (playerPosition[0] < np[0]) {
        newPosition[0] = newPosition[0] + shift;
        pressedKeys[0] = "ArrowRight";
    }

    if (playerPosition[1] > np[1]) {
        newPosition[1] = newPosition[1] - shift;
        pressedKeys[1] = "ArrowUp";
    } else if (playerPosition[1] < np[1]) {
        newPosition[1] = newPosition[1] + shift;
        pressedKeys[1] = "ArrowDown";
    }

    return [newPosition, pressedKeys];
}


const exportToJson = (actions: ActionState[]): void => {
    const blob = new Blob([JSON.stringify(actions, null, 2)], { type: 'application/json' });

    saveFile(blob);
}

const saveFile = async (blob: any) => {
    const a = document.createElement('a');
    a.download = 'data.json';
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
};

export {
    getRandom,
    normalizeCoordinate,
    getBlockDistance,
    keysToPlayerActions,
    exportToJson,
    getNextMove,
    isPositionsEqaul,
};
